define("poopon/models/diaper", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)("string"),
    ageMax: (0, _model.attr)("number"),
    ageMin: (0, _model.attr)("number"),
    variant: (0, _model.attr)("string"),
    productCount: (0, _model.attr)("number"),
    description: (0, _model.attr)("string"),
    image: (0, _model.attr)("string"),
    lowestPricePerDiaper: (0, _model.attr)("number"),
    ean: (0, _model.attr)("string"),
    size: (0, _model.attr)("string"),
    weightMax: (0, _model.attr)("number"),
    weightMin: (0, _model.attr)("number"),
    brand: (0, _model.belongsTo)("brand"),
    products: (0, _model.hasMany)("product"),
    diaperChanges: (0, _model.hasMany)("diaper-change")
  });

  _exports.default = _default;
});