define("poopon/controllers/diapers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    brandsSorted: Ember.computed.sort("brands", "brandsSorting"),
    init: function init() {
      this._super.apply(this, arguments);

      this.brandsSorting = ["name"];
    },
    searchMatcher: function searchMatcher(brand, term) {
      return brand.get("name").toLowerCase().indexOf(term.toLowerCase());
    },
    actions: {
      brandChange: function brandChange(brand) {
        var model = this.model;
        model.set("brand", brand);
      },
      modelSave: function modelSave() {
        var model = this.model;
        var self = this;
        model.save().then(function () {
          self.transitionToRoute("diapers.index");
        });
      }
    }
  });

  _exports.default = _default;
});