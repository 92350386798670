define("poopon/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _jsonApi.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, clazz, payload) {
      var result = this._super.apply(this, arguments);

      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.pagination = this.createPageMeta(payload.links);
      }

      return result;
    },
    createPageMeta: function createPageMeta(data) {
      var meta = {};
      Object.keys(data).forEach(function (type) {
        var link = data[type];
        meta[type] = {};
        var a = document.createElement("a");
        a.href = link;
        a.search.slice(1).split("&").forEach(function (pairs) {
          var _pairs$split = pairs.split("="),
              _pairs$split2 = _slicedToArray(_pairs$split, 2),
              param = _pairs$split2[0],
              value = _pairs$split2[1];

          if (param == "page[page]") {
            meta[type].number = parseInt(value);
          }

          if (param == "page[size]") {
            meta[type].size = parseInt(value);
          }
        });
        a = null;
      });
      return meta;
    }
  });

  _exports.default = _default;
});