define("poopon/routes/products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      controller.set("diapers", this.store.findAll("diaper"));
    }
  });

  _exports.default = _default;
});