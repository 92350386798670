define("poopon/templates/components/pagination-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b96elwVK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"model\",\"meta\",\"pagination\",\"prev\",\"number\"]],[24,[\"model\",\"meta\",\"pagination\",\"next\",\"number\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"pagination-button prev\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"meta\",\"pagination\",\"prev\",\"number\"]]],null,{\"statements\":[[0,\"      \"],[5,\"link-to\",[[12,\"class\",\"button gray condensed\"]],[[\"@route\",\"@query\"],[[22,\"path\"],[28,\"hash\",null,[[\"page\"],[[24,[\"model\",\"meta\",\"pagination\",\"prev\",\"number\"]]]]]]],{\"statements\":[[0,\"vorige\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"button inactive condensed\"],[8],[0,\"vorige\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pagination-button next\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"meta\",\"pagination\",\"next\",\"number\"]]],null,{\"statements\":[[0,\"      \"],[5,\"link-to\",[[12,\"class\",\"button gray condensed\"]],[[\"@route\",\"@query\"],[[22,\"path\"],[28,\"hash\",null,[[\"page\"],[[24,[\"model\",\"meta\",\"pagination\",\"next\",\"number\"]]]]]]],{\"statements\":[[0,\"volgende\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"button inactive condensed\"],[8],[0,\"volgende\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "poopon/templates/components/pagination-buttons.hbs"
    }
  });

  _exports.default = _default;
});