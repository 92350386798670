define("poopon/controllers/products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchMatcher: function searchMatcher(diaper, term) {
      return "".concat(diaper.get("brand.name"), " ").concat(diaper.get("name"), " ").concat(diaper.get("size")).toLowerCase().indexOf(term.toLowerCase());
    },
    actions: {
      diaperChange: function diaperChange(diaper) {
        var model = this.model;
        model.set("diaper", diaper);
        model.save();
      },
      productSave: function productSave() {
        var model = this.model;
        model.save();
      },
      productDelete: function productDelete() {
        var self = this;
        var model = this.model;
        model.destroyRecord().then(function () {
          self.transitionToRoute("products.index");
        });
      }
    }
  });

  _exports.default = _default;
});