define("poopon/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    errorPassword: false,
    errorIdentification: false,
    errorMessage: false,
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties("identification", "password");
        var authenticator = "authenticator:token";
        this.session.authenticate(authenticator, credentials).catch(function (reason) {
          if (reason.error) {
            if (reason.error === "unauthorized") {
              _this.set("errorPassword", ["Ongeldig wachtwoord"]);
            } else if (reason.error === "Not found") {
              _this.set("errorIdentification", ["E-mailadres niet gevonden"]);
            } else {
              _this.set("errorMessage", ["Onbekende fout opgetreden"]);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});