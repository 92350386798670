define("poopon/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    actions: {
      sessionRequiresAuthentication: function sessionRequiresAuthentication() {
        this.session.authenticate("simple-auth-authenticator:jwt", {
          password: ""
        }).then(function () {// console.log('custom token authentication successful!');
        }, function () {// }, function (error) {
          // console.log('custom token authentication failed!', error);
        });
      }
    }
  });

  _exports.default = _default;
});