define("poopon/models/diaper-change", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    diaper: (0, _model.belongsTo)("diaper"),
    lowestPricePerDiaperDiff: (0, _model.attr)("number"),
    lowestPricePerDiaperNew: (0, _model.attr)("number"),
    lowestPricePerDiaperOld: (0, _model.attr)("number"),
    shouldSendNotification: (0, _model.attr)("nullable-boolean"),
    sendNotification: (0, _model.attr)("nullable-boolean"),
    sendNotificationAt: (0, _model.attr)("date"),
    insertedAt: (0, _model.attr)("date"),
    updatedAt: (0, _model.attr)("date")
  });

  _exports.default = _default;
});