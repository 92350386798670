define("poopon/templates/diapers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i5wIMPKL",
    "block": "{\"symbols\":[\"diaper\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Diapers\"],[9],[0,\"\\n\\n\"],[5,\"link-to\",[],[[\"@route\"],[\"diapers.new\"]],{\"statements\":[[0,\"new\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"table\",true],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Brand\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Product amount\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Variant\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Size\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Gewicht min\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Gewicht max\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Lowest price per diaper\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"modelSorted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"brand\",\"name\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"diapers.show\",[23,1,[]]]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]}],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"productCount\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"variant\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"size\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"weightMin\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"weightMax\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[28,\"format-money\",[[23,1,[\"lowestPricePerDiaper\"]]],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "poopon/templates/diapers/index.hbs"
    }
  });

  _exports.default = _default;
});