define("poopon/adapters/application", ["exports", "@ember-data/adapter/json-api", "poopon/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _jsonApi, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    namespace: _environment.default.apiNamespace,
    host: _environment.default.host,
    headers: Ember.computed("session.data.authenticated.token", function () {
      var headers = {};

      if (this.session.isAuthenticated) {
        // OAuth 2
        headers["Authorization"] = "Bearer ".concat(this.session.data.authenticated.token);
      }

      return headers;
    })
  });

  _exports.default = _default;
});