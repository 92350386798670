define("poopon/controllers/diapers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelSorted: Ember.computed.sort("model", "modelSorting"),
    init: function init() {
      this._super.apply(this, arguments);

      this.modelSorting = ["brand.name", "name", "size"];
    }
  });

  _exports.default = _default;
});