define("poopon/routes/diapers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord("diaper");
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      controller.set("brands", this.store.findAll("brand"));
    }
  });

  _exports.default = _default;
});