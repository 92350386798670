define("poopon/models/merchant", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)("string"),
    image_url: (0, _model.attr)("string"),
    merchantProducts: (0, _model.hasMany)("merchant-product")
  });

  _exports.default = _default;
});