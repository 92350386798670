define("poopon/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "icxlaG07",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row flex\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n\"],[4,\"paper-form\",null,[[\"onSubmit\"],[[28,\"action\",[[23,0,[]],\"authenticate\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-100\"],[8],[0,\"\\n          \"],[7,\"h1\",true],[8],[0,\"Login\"],[9],[0,\"\\n          \"],[5,\"paper-input\",[],[[\"@errors\",\"@label\",\"@value\",\"@onChange\"],[[22,\"errorIdentification\"],\"E-mailadres\",[22,\"identification\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"identification\"]]],null]],null]]]],[0,\"\\n          \"],[5,\"paper-input\",[],[[\"@errors\",\"@type\",\"@label\",\"@value\",\"@onChange\"],[[22,\"errorPassword\"],\"password\",\"Wachtwoord\",[22,\"password\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"password\"]]],null]],null]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n        \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"submit-button\"]],\"expected `form.submit-button` to be a contextual component but found a string. Did you mean `(component form.submit-button)`? ('poopon/templates/login.hbs' @ L13:C11) \"],null]],[[\"raised\",\"primary\"],[true,true]],{\"statements\":[[0,\"Login\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "poopon/templates/login.hbs"
    }
  });

  _exports.default = _default;
});