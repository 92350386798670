define("poopon/templates/merchants/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "obVOW32o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row flex\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"merchants.index\"]],{\"statements\":[[0,\"back\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-100\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[8],[0,\"Merchant '\"],[1,[24,[\"model\",\"name\"]],false],[0,\"'\"],[9],[0,\"\\n\\n        \"],[5,\"paper-input\",[],[[\"@value\",\"@label\",\"@validate\",\"@onChange\"],[[24,[\"model\",\"name\"]],\"Merchant name\",true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"name\"]]],null]],null]]]],[0,\"\\n        \"],[5,\"file-upload\",[],[[\"@id\",\"@modelName\",\"@paramName\",\"@model\"],[\"fileupload\",\"merchants\",\"image\",[22,\"model\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"image_url\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"model\",\"image_url\"]]]]],[11,\"alt\",[29,[[24,[\"model\",\"name\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"paper-button\",[[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"modelSave\"]]],null]]]],[[\"@primary\",\"@raised\"],[true,true]],{\"statements\":[[0,\"\\n      Save\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "poopon/templates/merchants/edit.hbs"
    }
  });

  _exports.default = _default;
});