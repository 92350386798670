define("poopon/templates/brands/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qMJehSij",
    "block": "{\"symbols\":[\"brand\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Brands\"],[9],[0,\"\\n\\n\"],[7,\"table\",true],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Slug\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Diaper amount\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Position\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Image\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"modelSorted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"brands.edit\",[23,1,[]]]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]}],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"slug\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"diaperCount\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"position\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[7,\"img\",true],[11,\"src\",[23,1,[\"image_url\"]]],[8],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "poopon/templates/brands/index.hbs"
    }
  });

  _exports.default = _default;
});