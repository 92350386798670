define("poopon/components/file-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader", "poopon/config/environment"], function (_exports, _fileField, _uploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend({
    store: Ember.inject.service(),
    url: "",
    modelName: "",
    paramName: "",
    filesDidChange: function filesDidChange(files) {
      var uploadUrl = _environment.default.host + "/" + _environment.default.apiNamespace + "/" + this.modelName + "/" + this.get("model.id") + "/upload";

      var uploader = _uploader.default.create({
        url: uploadUrl,
        method: "PUT",
        paramName: this.paramName
      });

      var self = this;

      if (!Ember.isEmpty(files)) {
        var promise = uploader.upload(files[0], {
          id: this.get("model.id")
        });
        promise.then(function (data) {
          self.get("store").pushPayload(data);
        }, function () {// Handle failure
        });
      }
    }
  });

  _exports.default = _default;
});