define("poopon/routes/diaper-changes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.store.query("diaper-change", {
        page: {
          page: params.page,
          "page-size": params.size
        }
      });
    }
  });

  _exports.default = _default;
});