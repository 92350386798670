define("poopon/models/merchant-product", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    currency: (0, _model.attr)("string"),
    price: (0, _model.attr)("number"),
    url: (0, _model.attr)("string"),
    image: (0, _model.attr)("string"),
    merchant: (0, _model.belongsTo)("merchant"),
    product: (0, _model.belongsTo)("product")
  });

  _exports.default = _default;
});