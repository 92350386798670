define("poopon/models/product", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)("string"),
    description: (0, _model.attr)("string"),
    currency: (0, _model.attr)("string"),
    ean: (0, _model.attr)("string"),
    image: (0, _model.attr)("string"),
    lowestPrice: (0, _model.attr)("number"),
    numberOfDiapers: (0, _model.attr)("number"),
    pricePerDiaper: (0, _model.attr)("number"),
    merchantProductCount: (0, _model.attr)("number"),
    diaper: (0, _model.belongsTo)("diaper"),
    merchantProducts: (0, _model.hasMany)("merchant-product"),
    productChanges: (0, _model.hasMany)("productChange")
  });

  _exports.default = _default;
});