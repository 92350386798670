define("poopon/controllers/diaper-changes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: "1",
    size: "50",
    init: function init() {
      this._super.apply(this, arguments);

      this.queryParams = ["page", "size"];
    },
    actions: {
      sendNotification: function sendNotification(diaperChange) {
        diaperChange.set("sendNotification", true);
        diaperChange.save();
      },
      cancelNotification: function cancelNotification(diaperChange) {
        diaperChange.set("sendNotification", false);
        diaperChange.save();
      }
    }
  });

  _exports.default = _default;
});