define("poopon/templates/products/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iLFaMKr9",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Products\"],[9],[0,\"\\n\\n\"],[7,\"table\",true],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"lowestPrice\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"numberOfDiapers\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"pricePerDiaper\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"merchantProductCount\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Diaper\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"modelSorted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"products.edit\",[23,1,[]]]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"lowestPrice\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"numberOfDiapers\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[28,\"format-money\",[[23,1,[\"pricePerDiaper\"]]],null],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"merchantProductCount\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"diaper\"]]],null,{\"statements\":[[0,\"            \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"products.edit\",[23,1,[]]]],{\"statements\":[[0,\"attach\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "poopon/templates/products/index.hbs"
    }
  });

  _exports.default = _default;
});