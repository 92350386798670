define("poopon/transforms/nullable-boolean", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === null) {
        return null;
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized === null) {
        return null;
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});